import React, { useEffect, useState } from "react";
// import SimpleModal from "./SimpleModal";
import { useStaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import color, { themeColors } from "../styles/colors";
import Button from "./elements/Button";
import { button } from "../styles/typography";
import vacationGif from "../images/illustrations/Beach_Desktop.webp";
import vacationGifMobile from "../images/illustrations/Beach_Mobile.webp";
import FancyModal from "./FancyModal";
import useBreakpoint from "use-breakpoint";
import { keyframes } from "@emotion/react";
import {
	grid,
	gridc,
	screen,
	container,
	spacingRestricted,
	fluidRestricted,
} from "../styles/mixins";
import SimpleModal from "./SimpleModal";

const BREAKPOINTS = { mobile: 0, tablet: 768 };
const eggplant = color("purple");

const Section = styled.section`
	position: relative;
	background-color: ${(props) => themeColors[props.colorTheme].bg};
	color: ${(props) => themeColors[props.colorTheme].fg};
	padding-bottom: 50px;
	overflow: hidden;

	@media ${screen("md")} {
		padding-bottom: 100px;
	}
`;

const ContainerEl = styled.div`
	${container()};
`;

const ContentWrap = styled.div`
	${gridc(3, 44)}
`;

const DesktopCta = styled.button`
	opacity: 0;
	visibility: hidden;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: ${color("beige")};
	border-radius: 20px 20px 90px 20px;
	transition: 0.3s ease;
	transition-property: opacity, visibility;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 30px;

	@media ${screen("lg")} {
		padding: 35px;
	}
`;

const DesktopCtaTitle = styled.span`
	color: ${color("purple")};
	display: block;
	font-size: 35px;
	line-height: 1.2;
	text-align: left;

	@media ${screen("lg")} {
		font-size: 45px;
	}
`;

// Stand-in for elements generated by the removed 'Reveal' plugin, so as
// we don't break the styles, cos they're impossible to reverse engineer.
const NoReveal = styled.div``;

const BlocksWrap = styled.div`
	${gridc(3, 44)}

	> div {
		margin-bottom: 25px;

		&:last-child {
			margin-bottom: 0;
		}

		&:first-child > div,
		&:first-child > div ${DesktopCta} {
			background-color: ${color("pink")};
		}

		&:last-of-type > div,
		&:last-of-type > div ${DesktopCta} {
			background-color: ${color("blue")};
			margin-bottom: 0;
		}

		@media ${screen("md")} {
			grid-column: span 8;
			gap: 90px;
			margin-bottom: 0;
		}
	}

	@media ${screen("md")} {
		${grid(24)}
		gap: 1.5em;
	}
`;

const Block = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: ${eggplant};
	padding: 1.7em;
	border-radius: 20px 20px 90px 20px;
	background-color: white;
	overflow: hidden;
	background-color: ${color("beige")};
	min-height: 355px;

	@media ${screen("md")} {
		padding: 1.5em;
		margin-bottom: 0;

		&:hover ${DesktopCta} {
			opacity: 1;
			visibility: visible;
		}
	}

	@media ${screen("lg")} {
		padding: 1.85em;
		border-radius: 15px 15px 100px 15px;
		aspect-ratio: 1/1;
	}
`;

const BlockHeading = styled.h3`
	font-size: 40px;
	line-height: 1.1;
	letter-spacing: -0.01em;
	font-weight: 500;

	em {
		font-style: normal;
		color: ${color("orange")};
	}

	@media ${screen("md")} {
		${fluidRestricted("font-size", 35, 44, "ml", "sm")}
	}

	@media ${screen("lg")} {
		margin-bottom: 40px;
		max-width: 600px;
		${spacingRestricted("padding-bottom", "xs")};
		${fluidRestricted("font-size", 45, 54, "md", "designD")}
	}
`;

const BlockText = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	font-size: 22px;
	line-height: 1.2;
	height: 100%;

	p:last-of-type {
		margin-bottom: 0;
	}

	@media ${screen("lg")} {
		font-size: 26px;
	}
`;

const Cta = styled.div`
	${button};
	${spacingRestricted("padding-bottom", "s")};

	@media ${screen("md")} {
		${spacingRestricted("padding-bottom", "l")};
	}
`;

const CtaButton = styled(Button)`
	margin-right: 0.5em;
	margin-bottom: 0.5em;
	width: auto;
	max-width: 500px;

	@media ${screen("md")} {
		max-width: none;
	}
`;

const CtaHeading = styled.h2`
	font-size: 48px;
	line-height: 1.1;
	letter-spacing: -0.01em;
	font-weight: 500;
	max-width: 300px;
	${spacingRestricted("margin-bottom", "s")};

	@media ${screen("md")} {
		margin-bottom: 40px;
		max-width: 600px;
		${spacingRestricted("margin-bottom", "m")};
		${fluidRestricted("font-size", 60, 68, "md", "designD")}
	}
`;

const DecoratorWrap = styled.div`
	transform: translateX(35%);
	width: 300px;
	margin-left: auto;
	margin-bottom: -100px;
	z-index: 1;

	@media ${screen("md")} {
		position: absolute;
		right: 0;
		top: 0;
		width: 40vw;
		max-width: 400px;
		transform: translateX(30%);
		margin-left: unset;
		margin-bottom: unset;
	}
`;

const ModalTrigger = styled.button`
	text-decoration: underline;
	display: inline-block;
	text-align: left;
	transition: 0.3s ease opacity;

	&:hover {
		opacity: 0.7;
	}
`;

const ModalMobileCta = styled(Button)`
	${button};
	width: auto;
	margin-top: 35px;
	display: inline-block;
`;

const RowFeaturedBlocks = ({ colorTheme, data }) => {
	const [modalState, setModalState] = useState(false);
	const query = useStaticQuery(graphql`
		query featuredBlocks {
			craft {
				entries(section: "modals") {
					... on CraftCMS_modals_fancyModals_Entry {
						id
						typeHandle
						title
						slug
						modalTitle
						modalList
						modalBackground
						modalIllustration
					}
					... on CraftCMS_modals_simpleModals_Entry {
						id
						typeHandle
						title
						slug
						modalTitle
						modalList
						modalBackground
					}
				}
			}
		}
	`);

	const { breakpoint, maxWidth, minWidth } = useBreakpoint(
		BREAKPOINTS,
		"tablet"
	);

	const modals = query?.craft?.entries;
	const featuredBlocks = data.blocks;
	const heading = data.heading;
	const buttonData = data.button;

	const customAnimation = keyframes`
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `;

	useEffect(() => {
		if (!modalState) {
			let state = {};

			modals.map((modal) => {
				state[modal.slug] = false;
			});

			setModalState(state);
		}
	}, [modalState, setModalState]);

	const handleCloseModal = (slug) => {
		setModalState({
			...modalState,
			[slug]: false,
		});
	};

	const handleClick = (e) => {
		let slug;
		let target = e.target;

		for (let desperation = 0; desperation < 4; desperation++) {
			if (target.dataset.slug) {
				slug = target.dataset.slug;
				break;
			} else {
				target = target.parentElement;
			}
		}

		setModalState({ ...modalState, [slug]: true });
	};

	return (
		heading &&
		featuredBlocks.length && (
			<Section colorTheme={colorTheme}>
				<ContainerEl>
					<ContentWrap>
						<DecoratorWrap>
							{breakpoint == "tablet" ? (
								<img src={vacationGif} alt="" />
							) : (
								<img src={vacationGifMobile} alt="" />
							)}
						</DecoratorWrap>
						<Cta>
							<CtaHeading as="h2">
								<span dangerouslySetInnerHTML={{ __html: heading }}></span>
							</CtaHeading>

							<CtaButton
								url={buttonData.url}
								linkType={buttonData.type}
								target={buttonData.target}
								element={buttonData.element}
								type={buttonData.style === "invert" ? "white" : "standard"}
								external={buttonData.type !== "entry"}
								data-event-name="select_cta"
								data-content-type={buttonData.text}
								data-content-destination={buttonData.url}
								data-content-text={buttonData.text}>
								{buttonData.text}

								{buttonData.subtext && (
									<span
										key="subtext"
										style={{
											display: "block",
											fontSize: "smaller",
											textTransform: "none",
										}}>
										{buttonData.subtext}
									</span>
								)}
							</CtaButton>
						</Cta>
						<BlocksWrap>
							{featuredBlocks.map((block, idx) => (
								<NoReveal>
									<Block key={idx}>
										<BlockHeading
											dangerouslySetInnerHTML={{ __html: block.heading }}
										/>

										<BlockText>
											<span dangerouslySetInnerHTML={{ __html: block.text }} />

											{block.modalLink?.element &&
												(() => {
													let link = block.modalLink?.element;
													let looksLikeModal =
														link.uri.match(/^modals\//i)?.length > 0;

													return looksLikeModal ? (
														<span
															onClick={handleClick}
															data-slug={block.modalLink?.element?.slug}
															style={{
																marginTop: "1rem",
																fontWeight: "bold",
																cursor: "pointer",
																textDecoration: "underline",
															}}>
															{block.modalLink?.text}
														</span>
													) : null /*  WIP allow non-modal links in CTA Blocks
													<a href={'/' + link.uri}
														style={{
															marginTop: '1rem',
															fontWeight: 'bold',
															cursor: 'pointer',
															textDecoration: 'underline',
														}}>
														{ block.modalLink?.text }
													</a> */;
												})()}
										</BlockText>
									</Block>
								</NoReveal>
							))}
						</BlocksWrap>
						{featuredBlocks.map((block, idx) => {
							return modals.map((modal) => {
								if (modal.slug === block.modalLink?.element?.slug) {
									switch (modal.typeHandle) {
										case "simpleModals":
											return (
												<SimpleModal
													open={modalState[modal.slug]}
													closeModal={() => handleCloseModal(modal.slug)}
													{...modal}
												/>
											);
										default:
											return (
												<FancyModal
													open={modalState[modal.slug]}
													closeModal={() => handleCloseModal(modal.slug)}
													{...modal}
												/>
											);
									}
								}
							});
						})}
					</ContentWrap>
				</ContainerEl>
			</Section>
		)
	);
};

RowFeaturedBlocks.propTypes = {
	colorTheme: PropTypes.string,
};

RowFeaturedBlocks.defaultProps = {
	colorTheme: "light",
};

export default RowFeaturedBlocks;
